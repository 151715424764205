jQuery(function($) {

    

    $( document ).ready(function() {

    /*

      Place code in this area.

    */



    var website_url = 'https://loscocosrestaurant.com';



    $(document).on('opening', '.remodal', function () {

      console.log('opening');

    });



    $(document).on('opened', '.remodal', function () {

      console.log('opened');

    });



    $(document).on('closing', '.remodal', function (e) {

      console.log('closing' + (e.reason ? ', reason: ' + e.reason : ''));

    });



    $(document).on('closed', '.remodal', function (e) {

      console.log('closed' + (e.reason ? ', reason: ' + e.reason : ''));

    });



    $(document).on('confirmation', '.remodal', function (e) {

      console.log('confirmation');

      console.log(e.target.id);

      var remodalId = e.target.id; // should be the item number

      var formId = 'form-' + remodalId;

      var formElement = $("#"+formId);

      console.log(formElement);



      var itemId = $("#item-id-"+remodalId);

      var key = $("#item-key-"+remodalId);

      var qty = $("#item-qty-"+remodalId);

      var price = $("#item-price-"+remodalId);

      var name = $("#item-name-"+remodalId);

      var action = $("#"+formId).attr('action');

      console.log(action);



      console.log('Item Id: ' + itemId.val());

      console.log('Key: ' + key.val());

      console.log('Qty: ' + qty.val());

      console.log('price: ' + price.val());

      console.log('name: ' + name.val());



      var postData = {id:itemId, qty:qty, key:key};

      var formData = $("#"+formId).serializeArray();

      console.log(formData);

      var data = {

        action: 'add',

        item_id: itemId.val(),

        item_name: name.val(),

        quantity: qty.val(),

        item_price: price.val()

      };

      $.ajax({

        url: action,

        type: 'post',

        dataType: 'json',

        data: data,

        success: function(data) {

          if(data == "ok") {

            alert('success');

          }

        },

        error: function(xhr, desc, err) {

          console.log(xhr);

          console.log("Details: " + desc + "\nError: " + err);

          return

        }

      });



    });



    $(document).on('cancellation', '.remodal', function () {

      console.log('cancellation');

    });



      $("#add-item-action").on("submit", function(e) {

        e.preventDefault();

        var itemId = $("#item-id").val();

        var itemName = $("#item-name").val();

        var itemQty = $("#item-qty").val();

        var itemPrice = $("#item-price").val();

        var key = $("#key").val();

        var url = $("#add-item-action").attr("action");

        var data = $("#add-item-action :input").serializeArray();

        console.log(data);



        sendAddItem(url, data);

      });



      function sendAddItem(url, data) {



        var payload = {action: 'add', item_id: '', item_name: '', quantity: 0, item_price: 0.00};

        for(var i = 0; i < data.length; i++) {

          switch(data[i].name) {

            case "item_id":

              payload.item_id = data[i].value;

              break;

            case "item_name":

              payload.item_name = data[i].value;

              break;

            case "quantity":

              payload.quantity = data[i].value;

              break;

            case "item_price":

              payload.item_price = data[i].value;

              break;

          }

        }



        $.ajax({

          url: url,

          type: 'post',

          dataType: 'json',

          data: payload,

          success: function(data) {

            if(data == "ok") {

              console.log('Item added');

            }

          },

          error: function(xhr, desc, err) {

            console.log(xhr);

            console.log("Details: " + desc + "\nError: " + err);

            return;

          }

        });

      }



      $("#mobile-button").click(function() {

        $("#mobile-menu").fadeToggle( 350 );

        $(".mobile-navigation").toggleClass("mobile-is-open");



        if($(".mobile-navigation").hasClass("mobile-is-open")) {

          $("#mobile-button").html('close');

        } else {

          $("#mobile-button").html('menu');

        }

      });



      $("#footer-menu-button").click(function() {

      

        $("#menu-footer").fadeToggle( 350 );

      });



      $("#appetizers-container").fadeIn( 350 );

      $("#appetizers-container").css('display', 'flex');



      $(".menu-list-item").click(function(e) {

      	e.preventDefault();

        console.log('menu item clicked');

        var cardLink = $(this).attr('for');

        var cardContainerID = cardLink + '-container';

        var menuLink = $(this).attr('data-link');

        var fullMenuButton = $('#full-menu-hp');

        fullMenuButton.attr('href', website_url + '/menu/' + menuLink);

        fullMenuButton.text('View more' + " " + $(this).text());

        $(".menu-cards").fadeOut( 100 );

        $("#"+cardContainerID).fadeToggle( 350 );

        $("#"+cardContainerID).css('display','flex');

        if(window.matchMedia("(max-width: 767px)").matches){
          window.location.hash="scrollhash"; 

          $('html, body').animate({
               'scrollTop':   $('#home-mobile-menu').offset().top
           }, 250);
        }
    		
      });


      // menu card click effects
      $(".menu-cards .mdl-card .mdl-card__actions").each(function() {

        $(this).css('display', 'none');

      });

      

      $(".menu-cards .mdl-card").click(function() {



        // reset all boxes

        $(".menu-cards .mdl-card").each(function() {

          var supporting_text = $(this).find('.mdl-card__supporting-text');

          var heading_text = $(this).find('header div');

          var actions = $(this).find('.mdl-card__actions');



          heading_text.css('display', 'none');

          supporting_text.fadeIn(); // default red item name bar

          actions.css('display', 'none');

        });



        // set active box

        var supporting_text = $(this).find('.mdl-card__supporting-text');

        var heading_text = $(this).find('header div');

        var actions = $(this).find('.mdl-card__actions');



        heading_text.fadeIn();

        actions.fadeIn();

        supporting_text.css('display', 'none');

      });



      /*

        Side Menu Click Expansion of Sub Menus

      */

      if($('#menuSide').length) {

        var subMenuParent = $('#menuSide .menu-item-has-children');

        subMenuParent.click(function(event) {

          

          var subMenuUL = $(this).children(".sub-menu");

          if(subMenuUL.css('display') == 'none') {

            // We need to show this element

            subMenuUL.css('display', 'block');

          } else {

            // Hide the element

            subMenuUL.css('display', 'none');

          }

        });

      }

      

    }); // End Document Ready

  }); // End jQuery

